<!--
 * @Author: wyx
 * @Date: 2023-11-10 14:45:30
 * @LastEditTime: 2024-06-12 15:02:03
 * @LastEditors: wenwen
 * @Description:预览
-->
<template>
  <div class="resourceManage fx-col fx-1">
    <template v-if="!uploadFileShow">
      <el-card>
        <div slot="header">
          <el-form
            class="form-item-no-mb"
            ref="searchForm"
            inline
            :model="searchForm"
            size="small"
          >
            <el-form-item label="文件名">
              <el-input
                v-model="searchForm.fileName"
                clearable
                placeholder="请输入文件名"
                @change.native="query"
              ></el-input>
            </el-form-item>
            <el-form-item label="文件类型">
              <el-select v-model="searchForm.fileType" clearable placeholder="请选择">
                <el-option
                  v-for="item in fileTypeList"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="创建时间">
              <el-date-picker
                v-model="searchForm.time"
                :default-time="['00:00:00', '23:59:59']"
                type="datetimerange"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button @click="reset">重置</el-button>
              <el-button style="margin-left:16px;" type="primary" @click="query(1)">查询</el-button>
            </el-form-item>

          </el-form>
        </div>
        <div>
          <el-button type="primary" @click="_uploadFileHandle"
            >上传文件</el-button
          >
          <el-button class="delet-group-btn add-group-btn" :style="{'--lock-btn': $store.state.settings.theme}" @click="deleteWhole"
            >删除</el-button
          >
        </div>
      </el-card>
      <div class="fx-row mt16 bac-fff fx-1">
        <!-- 资源分组 -->
        <ResourceGrouping
          :treeData="treeData"
          :defaultProps="defaultProps"
          @clickGroupNode="_clickGroupNode"
          @editGroupHandle="_editGroupHandle"
          @deleteGrouphandle="_deleteGrouphandle"
          @addGroupHandle="_addGroupHandle"
        />
        <!-- 列表 -->
        <div class="w200 fx-1 width-group-table" style="padding-top:8px;">
          <el-table
            :header-cell-style="cellHeaderStyle"
            :cell-style="cellStyle"
            class="index-list-table"
            :data="tableData"
            height="calc(100vh - 350px)"
            @selection-change="handleSelectionChange"
          >
          <el-table-column
            type="selection"
            width="55">
          </el-table-column>
            <el-table-column prop="fileName" label="文件名称">
              <template slot-scope="{row}">
                <el-popover trigger="hover" placement="top">
                  <span style="font-size: 12px;color: #303133;" > {{ row.fileName }}</span>
                  <div slot="reference" class="hover-title">
                    {{ row.fileName }}
                  </div>
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column prop="groupName" label="所属分组" width="180">
              <template slot-scope="{row}">{{row.groupName||'—'}}</template>

            </el-table-column>
            <el-table-column prop="fileTypeValue" label="文件类型" width="100">
              <template slot-scope="{row}">{{row.fileTypeValue||'—'}}</template>

            </el-table-column>
            <el-table-column prop="size" label="文件大小" width="100">
              <template slot-scope="{row}">{{row.size?bytesToSize(row.size):'—'}}</template>

            </el-table-column>
            <el-table-column
              prop="fileDuration"
              label="文件时长"
              width="100"
            >
            <template slot-scope="{row}">{{row.fileDuration?formatSeconds(row.fileDuration):'—'}}</template>
          </el-table-column>
            <el-table-column prop="createName" label="创建人" width="100">
              <template slot-scope="{row}">{{row.createName||'—'}}</template>

            </el-table-column>
            <el-table-column prop="teacherUserNames" label="带教老师" width="100">
              <template slot-scope="{row}">{{row.teacherUserNames||'—'}}</template>

            </el-table-column>
            <el-table-column prop="createTime" label="创建时间"                                                   >
              <template slot-scope="{row}">{{row.createTime||'—'}}</template>

            </el-table-column>
            <el-table-column label="操作" width="120px" class="option-btn">
              <template slot-scope="{row}">
                <el-button
                  type="text"
                  class="text-btn mr24"
                  @click="_editHandle(row)"
                  >编辑</el-button
                >
                <el-dropdown>
                  <el-button type="text" class="text-btn"
                    >更多<i class="el-icon-arrow-down"></i
                  ></el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="downloadFile(row)">下载</el-dropdown-item>
                    <el-dropdown-item @click.native="_previewFileHandle(row)"
                      >预览</el-dropdown-item
                    >
                    <el-dropdown-item @click.native="deleteTable(row)"
                      >删除</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            class="fx-row-end pt16"
            @size-change="_handleSizeChange"
            @current-change="_handleCurrentChange"
            :current-page="searchForm.page"
            :page-sizes="[10, 20, 30, 40, 50]"
            :page-size="searchForm.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="searchForm.total"
          >
          </el-pagination>
        </div>
      </div>
    </template>
    <!-- 列表预览 -->
    <PreviewFile
      v-if="previewFileShow"
      :previewFileShow="previewFileShow"
      :currentFile="currentFile"
      ref="previewFile"
    ></PreviewFile>
    <!-- 列表编辑 -->
    <EditFile
      v-if="editFileShow"
      :treeData="treeData"
      nodeKey="value"
      :defaultProps="defaultProps"
      :editFileShow="editFileShow"
      :currentFile="currentFile"
      ref="editFile"
    ></EditFile>
    <!-- 资源分组编辑 -->
    <EditGroup
    pageType="sourceGroup"
      v-if="editGroupShow"
      @editSubmitGroup="_editSubmitGroup"
      nodeKey="value"
      :defaultProps="defaultProps"
      :treeData="treeDataGroup"
      :editGroupShow="editGroupShow"
      :currentNode="currentNode"
      pageValue="otherGroup"
      type="resource"
      ref="editGroup"
    ></EditGroup>
    <!-- 资源分组添加 -->
    <AddGroup
      pageType="sourceGroup"
      v-if="addGroupShow"
      @submiAddGroup="_submiAddGroup"
      :defaultProps="defaultProps"
      :treeData="treeData"
      :addGroupShow="addGroupShow"
      nodeKey="value"
      ref="addGroup"
    ></AddGroup>
    <!-- 分组删除 -->
    <DeleteGroup v-if="deleteGroupShow" :deleteGroupShow="deleteGroupShow" />
    <!-- 选择部门 -->
    <SelectDepartment v-if="selectDepartmentShow" />

  </div>
</template>
<script>
import EditFile from './components/editFile.vue'
import EditGroup from '@/components/editGroup/index.vue'
import AddGroup from '@/components/addGroup/index.vue'
import PreviewFile from '@/components/previewFile/index.vue'
import DeleteGroup from '@/components/deleteGroup/index.vue'
import SelectDepartment from './components/selectDepartment.vue'
import ResourceGrouping from '@/components/resourceGrouping/index.vue'
import {
  getResourcelistApi,
  getTreeApi,
  addAndEditTreeApi,
  deleteTreeApi,
  fileDeleteApi,
  getFilePreviewApi
} from '@/api/resource'
import fileType from '@/mixins/fileType'
export default {
  mixins: [fileType],
  components: {
    EditFile,
    EditGroup,
    AddGroup,
    PreviewFile,
    DeleteGroup,
    SelectDepartment,
    ResourceGrouping
  },
  data () {
    return {
      tableData: [],
      searchForm: {
        fileName: '',
        fileType: '',
        groupId: '',
        time: [],
        page: 1,
        pageSize: 10,
        total: 1
      },
      fileTypeList: [
        {
          name: '图片',
          value: 'image'
        },
        {
          name: '视频',
          value: 'video'
        },
        {
          name: '文档',
          value: 'file'
        }
      ], // 文件类型
      treeData: [],
      treeDataGroup: [], // 分组使用
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      tableTotal: 0,
      editFileShow: false,
      addGroupShow: false,
      editGroupShow: false,
      previewFileShow: false,
      uploadFileShow: false,
      deleteGroupShow: false,
      selectDepartmentShow: false,
      currentNode: null, // 编辑选择当前节点
      currentFile: {}, // 当前行数据
      currentFileUrl: '',
      multipleSelection: []
    }
  },
  created () {
    this.getTree()
    this.query()
  },
  methods: {
    deleteWhole () {
      // 批量删除
      const ids = []
      this.multipleSelection.forEach(item => {
        ids.push(item.id)
      })
      this.deleteTable(ids)
    },
    handleSelectionChange (val) {
      this.multipleSelection = val
    },
    query (type) {
      // 查询
      if (type) {
        this.searchForm.page = 1
      }
      this.searchForm.createStartTime = this.searchForm.time ? this.searchForm.time[0] : null
      this.searchForm.createEndTime = this.searchForm.time ? this.searchForm.time[1] : null
      getResourcelistApi({ ...this.searchForm }).then((res) => {
        if (res.code === 200) {
          this.tableData = res.object.results
          this.searchForm.total = res.object.total
        }
      })
    },
    _clickGroupNode (data) {
      this.searchForm.groupId = data.value
      this.searchForm.page = 1
      this.query()
    },
    _uploadFileHandle () {
      // 上传文件
      this.$router.push({
        path: '/uploadFile?pageType=resource&groupId=' + this.searchForm.groupId
      })
    },
    _editHandle (row) {
      this.editFileShow = true
      this.currentFile = row
    },
    getTree () {
      // 获取资源分组树形结构
      getTreeApi({
        parentId: '0'
      }).then((res) => {
        if (res.code === 200) {
          this.treeData = JSON.parse(JSON.stringify(res.data))
        }
      })
    },
    _editGroupHandle (node) {
      // 分组不能选择子集分组，将当前分组的子集清空--start
      const tempGroup = JSON.parse(JSON.stringify(this.treeData))
      tempGroup.forEach((item, index) => {
        if (node.data.value === item.value) {
          item.children = []
        }
        if (item.children && item.children.length !== 0) {
          item.children.forEach((itemChild, childIndex) => {
            if (node.data.value === itemChild.value) {
              itemChild.children = []
            }
          })
        } else {
          item.children = []
        }
      })
      this.treeDataGroup = tempGroup
      // 分组不能选择子集分组，将当前分组的子集清空--end
      // 资源分组编辑
      this.currentNode = node.data.sourceGroup
      this.editGroupShow = true
    },
    _editSubmitGroup (item) {
      // 资源分组编辑提交
      const obj = {
        ...item,
        id: this.currentNode.id,
        groupNames: [item.groupName]
      }
      addAndEditTreeApi(obj).then((res) => {
        if (res.code === 200) {
          this.editGroupShow = false
          this.getTree()
        }
      })
    },
    _addGroupHandle () {
      // 资源分组添加
      this.addGroupShow = true
    },
    _submiAddGroup (item) {
      // 资源分组提交
      const groupNameArr = []
      item.groupNameList.forEach((item) => {
        groupNameArr.push(item.groupName)
      })
      const obj = {
        ...item,
        groupNames: groupNameArr
      }
      addAndEditTreeApi(obj).then((res) => {
        if (res.code === 200) {
          this.addGroupShow = false
          this.getTree()
        }
      })
    },
    _deleteGrouphandle (node) {
      // 资源分组删除
      this.$confirm('确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteTreeApi({ id: node.data.sourceGroup.id }).then((res) => {
          if (res.code === 200) {
          // this.deleteGroupShow = true
            this.getTree()
          }
        })
      })
    },
    _previewFileHandle (row) {
      // 列表预览
      if (row.fileType === 'image' || row.fileType === 'video') {
        this.previewFileShow = true
        this.currentFile = row
      } else {
        const previewUrl = this.getPreviewUrl(row)
        window.open(previewUrl)
      }
    },
    _handleSizeChange (val) {
      this.searchForm.pageSize = val
      this.query()
    },
    _handleCurrentChange (val) {
      this.searchForm.page = val
      this.query()
    },
    reset () {
      // 重置
      this.searchForm = {
        fileName: '',
        fileType: '',
        page: 1,
        pageSize: 10,
        groupId: this.searchForm.groupId
      }
      this.query()
    },

    deleteTable (row) {
      // 列表删除
      this.$confirm('确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        fileDeleteApi(row.id ? [row.id] : row).then(res => {
          if (res.code === 200) {
            this.query()
          }
        })
      })
    }
  }
}
</script>
<style lang="scss" scoped>

.form-item-no-mb {
  /deep/.el-form-item {
    margin-bottom: 0;
  }
}
/deep/.index-list-table {
  thead {
    background-color: #ffffff !important;
  }
  tr {
    height: 51px;
    height: 54px;
    color: rgba(0, 0, 0, 0.9);
    font-size: 14px;
  }
  th {
    background: #f6f7f9 !important;
  }
}
/deep/.el-form-item {
  margin-bottom: 16px !important;
  margin-right: 32px !important;
}
/deep/.el-form-item__label {
  color: #000000;
}
/deep/ .el-button {
  font-size: 14px;
  height: 32px;
}
/deep/.el-button--text {
  margin: 0px 10px 0px 0px!important;
}

</style>
