<template>
  <el-dialog
    :visible.sync="deleteGroupShow"
    :before-close="cancel"
    :close-on-click-modal="false"
    title="资源占用提示"
    width="400px"
  >
    <div slot="title" class="header-title">
      <svg
        t="1700015762243"
        class="icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="3163"
        width="19.2"
        height="19.2"
      >
        <path
          d="M512 1024A512 512 0 1 0 512 0a512 512 0 0 0 0 1024zM460.8 278.755556a51.2 51.2 0 1 1 102.4 0v295.822222a51.2 51.2 0 1 1-102.4 0V278.755556z m102.4 455.111111a51.2 51.2 0 1 1-102.4 0 51.2 51.2 0 0 1 102.4 0z"
          fill="#FAAD14"
          p-id="3164"
        ></path>
      </svg>
      <span>资源占用提示</span>
    </div>
    <div class="center">
      <div class="tips">资源已被使用，不能删除</div>
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="date" label="类型" width="180">
        </el-table-column>
        <el-table-column prop="name" label="名称" width="180">
        </el-table-column>
      </el-table>
    </div>
    <div slot="footer">
      <el-button @click="cancel">取消</el-button>
      <el-button type="primary" @click="submitHandle()">确认</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  props: {
    deleteGroupShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableData:[],
    };
  },
  methods: {
    cancel() {
      this.$parent.deleteGroupShow = false;
    },
    submitHandle() {
      this.$parent.deleteGroupShow = false;
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/.el-dialog__body {
  padding-top: 10px !important;
}
.header-title {
  display: flex;
  align-items: center;
  svg {
    margin-right: 8px;
  }
}
.center {
  .tips {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
  }
}
</style>
