<template>
  <el-dialog
    :visible.sync="selectDepartmentShow"
    :before-close="cancel"
    :close-on-click-modal="false"
    title="选择部门"
    width="600px"
  >
    <div class="center">
      <!-- 左边选择部门 -->
      <div class="department-left-content">
        <div class="tip-title">部门</div>
        <el-input placeholder="请输入" v-model="filterText">
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
        <div class="breadcrumb">
          <el-scrollbar style="height: 100%">
            <ul class="breadcrumb-ul">
              <li
                class="breadcrumb-item"
                v-for="(item, index) in menubarList"
                :key="index"
                :class="item.id === currentNodeId ? 'default' : 'active'"
                @click="changeMenubar(item, index)"
              >
                {{ item.label }}
                <svg
                  v-if="index + 1 != menubarList.length"
                  t="1700029922321"
                  class="icon"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  p-id="3297"
                  width="9"
                  height="12"
                >
                  <path
                    d="M413.44 797.44l-58.88-58.816 226.56-226.56-226.56-226.56 58.88-58.88 285.376 285.44-285.44 285.44z"
                    fill="#999999"
                    p-id="3298"
                  ></path>
                </svg>
              </li>
            </ul>
          </el-scrollbar>
        </div>
        <el-checkbox v-model="selectAll" @change="changeAll">全选</el-checkbox>
        <el-tree
          :data="treeData"
          show-checkbox
          node-key="id"
          ref="tree"
          :expand-on-click-node="false"
          :filter-node-method="filterNode"
          @check="handleTreeNodeClick"
          @node-click="onNodeClick"
        >
          <div class="custom-tree-node" slot-scope="{ node, data }">
            <div>{{ node.label }}</div>
            <div class="tree-right-icon">
              4人
              <svg
                t="1700029922321"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="3297"
                width="9"
                height="12"
              >
                <path
                  d="M413.44 797.44l-58.88-58.816 226.56-226.56-226.56-226.56 58.88-58.88 285.376 285.44-285.44 285.44z"
                  fill="#999999"
                  p-id="3298"
                ></path>
              </svg>
            </div>
          </div>
        </el-tree>
      </div>
      <!-- 右边显示部门 -->
      <div class="department-right-content">
        <div class="choosed-info">
          <div class="choosed-num">
            <span>已选:</span>
            部门1个，人员2个
          </div>
          <div class="delete-choosed-node">
            <el-button type="text" @click="deleteChoosedNode">清除</el-button>
          </div>
        </div>
        <div class="choosed-content">
          <div
            class="item-content"
            v-for="(item, index) in choosedTreeNode"
            :key="index"
          >
            <div class="item-name">{{ item.label }}</div>
            <div class="item-num">
              <span> 4人</span>
              <svg
                t="1700041639592"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="3431"
                width="8"
                height="8"
              >
                <path
                  d="M512 432.512L865.92 78.464a56.192 56.192 0 1 1 79.616 79.616L591.36 512l353.92 353.92a56.064 56.064 0 0 1-61.312 91.776 56.192 56.192 0 0 1-18.176-12.16L512 591.36 158.08 945.536a56.192 56.192 0 1 1-79.488-79.616L432.512 512 78.464 158.08a56.192 56.192 0 1 1 79.616-79.488L512 432.512z"
                  fill="#bfbfbf"
                  p-id="3432"
                ></path>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div slot="footer">
      <el-button @click="cancel">取消</el-button>
      <el-button type="primary" @click="submitHandle()">确认</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { getDartmentApi } from '@/api/api'
export default {
  props: {
    selectDepartmentShow: {
      type: Boolean,
      default: true
    }
  },
  data () {
    const data = [
      {
        id: 1,
        label: '一级 1',
        children: [
          {
            id: 4,
            label: '二级 1-1',
            children: [
              {
                id: 9,
                label: '三级 1-1-1'
              },
              {
                id: 10,
                label: '三级 1-1-2'
              }
            ]
          }
        ]
      },
      {
        id: 2,
        label: '一级 2'
      },
      {
        id: 3,
        label: '一级 3',
        children: [
          {
            id: 7,
            label: '二级 3-1'
          },
          {
            id: 8,
            label: '二级 3-2'
          }
        ]
      }
    ]
    return {
      treeData: JSON.parse(JSON.stringify(data)),
      treeDataCopy: JSON.parse(JSON.stringify(data)),
      choosedTreeNode: [], // 为了全选选中节点，不然会多个children时会导致点击多次才能选中
      dataForm: {
        name: 123
      },
      menubarList: [
        {
          label: '全部',
          id: '-1'
        }
      ],
      selectAll: false,
      filterText: '',
      currentNodeId: ''
    }
  },
  created () {
    this.getDepartmentData()
  },
  watch: {
    filterText (val) {
      console.log(val)
      this.$refs.tree.filter(val)
    }
  },
  methods: {
    cancel () {
      this.$parent.selectDepartmentShow = false
    },
    getDepartmentData () {
      getDartmentApi({
        isEnable: 1
      }).then((res) => {
        console.log(res)
      })
    },
    submitHandle () {
      this.selectDepartmentShow = false
    },
    changeAll (e) {
      // 全选

      console.log(e)
      if (e) {
        if (this.choosedTreeNode.length) {
          this.$refs.tree.setCheckedNodes(this.choosedTreeNode)
        } else {
          this.choosedTreeNode = []
          this.$refs.tree.setCheckedNodes(this.treeData)
        }
        this.choosedTreeNode = JSON.parse(JSON.stringify(this.treeData))
      } else {
        this.$refs.tree.setCheckedKeys([])
        this.choosedTreeNode = []
      }
    },
    deleteChoosedNode () {
      // 清除已选内容
      this.$refs.tree.setCheckedKeys([])
    },
    filterNode (value, data) {
      // 过滤
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    onNodeClick (edata, node, obj) {
      if (!(edata.children && edata.children.length)) return
      if (this.currentNodeId == edata.id) {
        this.menubarList.forEach((item, index) => {
          console.log(item, '=====面包屑的节点')
          if (item.id === edata.id) {
            this.menubarList.splice(index, 1)
          }
        })
      }
      this.menubarList = [...this.menubarList, edata]
      this.treeData = edata.children
      this.choosedTreeNode = JSON.parse(JSON.stringify(edata.children))
      const currentId = []
      edata.children.forEach((item) => {
        currentId.push(item.id)
      })
      this.selectAll = true
      this.$refs.tree.setCheckedKeys(currentId)
      this.currentNodeId = edata.id
    },
    handleTreeNodeClick (edata, node, obj) {
      // 选择树形复选框
      if (node.checkedKeys.includes(edata.id)) {
        this.choosedTreeNode.push(edata)
      } else {
        for (let i = 0; i < this.choosedTreeNode.length; i++) {
          if (!node.checkedKeys.includes(this.choosedTreeNode[i].id)) {
            this.choosedTreeNode.splice(i, 1)
            console.log(this.treeData)
          }
        }
      }
      console.log(edata, node, obj, '树形节点')
    },
    changeMenubar (item, index) {
      // 点击面包屑
      if (this.currentNodeId === item.id) return

      // 获取当前节点的id，来更改样式
      this.currentNodeId = item.id
      if (item.id == '-1') {
        // 点击全部的时候数据要显示全部
        this.menubarList = [
          {
            label: '全部',
            id: '-1'
          }
        ]
        this.treeData = this.treeDataCopy
      } else {
        // 点击面包屑时，节点要放回到当前节点
        this.treeData = item.children
        this.choosedTreeNode = JSON.parse(JSON.stringify(item.children))
        this.menubarList = [
          {
            label: '全部',
            id: '-1'
          },
          item
        ]
      }
      console.log(item, this.treeData, '点击面包屑的节点')
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/.el-dialog__body {
  padding: 0px !important;
  max-height: 500px;
  overflow: auto;
}
.header-title {
  display: flex;
  align-items: center;
  svg {
    margin-right: 8px;
  }
}
.center {
  display: flex;
  align-items: baseline;
  .department-left-content {
    flex: 1;
    border-right: 1px solid #f3f3f3;
    padding: 16px 20px;
    .tip-title {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.9);
      margin-bottom: 8px;
    }
    .breadcrumb {
      margin-top: 16px;
      background: rgba(51, 51, 51, 0.04);
      border-radius: 0px 0px 0px 0px;
      padding: 3px 8px 0 8px;
      margin-bottom: 10px;
      /deep/ .el-breadcrumb__item {
        margin-bottom: 8px;
      }
    }
    .breadcrumb-ul {
      display: flex; /* 将子元素横向排列 */
      flex-wrap: wrap;
      align-items: center;
      .breadcrumb-item {
        margin-right: 5px;
        margin-bottom: 5px;
        font-size: 14px;
      }
    }
    .custom-tree-node {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
  }
  .department-right-content {
    flex: 1;
    padding: 16px 20px;
    .choosed-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .choosed-num {
        color: #000000;
        font-size: 14px;
        span {
          color: #666666;
        }
      }
    }
    .choosed-content {
      .item-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;
        .item-num {
          display: flex;
          align-items: center;
          span {
            margin-right: 8px;
          }
        }
      }
    }
  }
}

.active {
  color: #0089ff;
}
.default {
  color: #666666;
}
</style>
